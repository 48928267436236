h1 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.375;
}

body {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.375;
}

body--active {
  font-weight: 600;
}

* {
  overscroll-behavior: none;
  scrollbar-width: auto;
  scrollbar-color: #b4d678 #333;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #4f4f4f;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb {
  background-color: #b4d678;
  border: 1px solid #4f4f4f;
  border-radius: 999px;
}

.property-icon, .property__icon {
  -webkit-user-select: none;
  user-select: none;
  width: 32px;
  height: 32px;
  filter: brightness(0) invert();
  justify-content: center;
  align-items: center;
  display: flex;
}

.information-icon {
  -webkit-user-select: none;
  user-select: none;
  width: 48px;
  height: 48p;
  cursor: pointer;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.information-icon:hover {
  background: #333;
}

.toggle-menu-icon {
  -webkit-user-select: none;
  user-select: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.toggle-menu-icon:hover {
  background: #333;
}

.point-visibility {
  -webkit-user-select: none;
  user-select: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.point-visibility__inactive-icon {
  filter: invert(.5);
  transition: all .2s;
  position: absolute;
}

.point-visibility:hover .point-visibility__inactive-icon {
  filter: invert(0);
}

.point-visibility__active-icon {
  opacity: 0;
  filter: brightness(2);
  transition: all .2s;
  position: absolute;
}

.point-visibility:hover .point-visibility__active-icon {
  filter: brightness();
}

.point-visibility--active .point-visibility__active-icon {
  opacity: 1;
}

.point-visibility--active .point-visibility__inactive-icon {
  opacity: 0;
}

.expand-minimize {
  -webkit-user-select: none;
  user-select: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.expand-minimize:hover {
  background: #333;
}

.expand-minimize__minus-icon, .expand-minimize__plus-icon {
  transition: all .2s;
  position: absolute;
}

.expand-minimize--active .expand-minimize__plus-icon {
  transform: scaleY(0);
}

.expand-minimize--inactive .expand-minimize__plus-icon, .expand-minimize__plus-icon {
  transform: scaleY(1);
}

.point-info-toggle {
  -webkit-user-select: none;
  user-select: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.point-info-toggle__inactive-icon, .point-info-toggle__active-icon {
  filter: invert(.5);
  transition: all .4s;
  position: absolute;
}

.point-info-toggle__inactive-icon {
  opacity: 1;
}

.point-info-toggle__active-icon {
  opacity: 0;
}

.point-info-toggle:hover .point-info-toggle__inactive-icon, .point-info-toggle:hover .point-info-toggle__active-icon {
  filter: invert(0);
}

.point-info-toggle--active .point-info-toggle__inactive-icon {
  opacity: 0;
}

.point-info-toggle--active .point-info-toggle__active-icon {
  opacity: 1;
}

.point-info-close {
  -webkit-user-select: none;
  user-select: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.point-info-close__icon {
  filter: invert(.5);
  transition: all .4s;
}

.point-info-close:hover .point-info-close__icon {
  filter: invert(0);
}

.close-menu-icon, .open-menu-icon {
  -webkit-user-select: none;
  user-select: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.close-menu-icon:hover, .open-menu-icon:hover {
  background: #333;
}

.option {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: all .2s;
}

.option:hover, .option--active {
  color: #b4d678;
}

.legend-toggler {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
}

.legend-toggler:hover img {
  filter: brightness() invert(0);
}

.legend-toggler img {
  filter: brightness(0) invert();
}

.legend-toggler--active img {
  filter: brightness() invert(0);
}

.opacity-toggler {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
}

.opacity-toggler:hover img {
  filter: brightness() invert(0);
}

.opacity-toggler img {
  filter: brightness(0) invert();
}

.opacity-toggler--active img {
  filter: brightness() invert(0);
}

h1 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.375;
}

body {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.375;
}

body--active {
  font-weight: 600;
}

* {
  overscroll-behavior: none;
  scrollbar-width: auto;
  scrollbar-color: #b4d678 #333;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #4f4f4f;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb {
  background-color: #b4d678;
  border: 1px solid #4f4f4f;
  border-radius: 999px;
}

.checkbox {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  text-transform: uppercase;
  color: #777;
  align-items: center;
  font-weight: bold;
  display: flex;
  position: relative;
}

.checkbox > input {
  display: none;
}

.checkbox__icon {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.checkbox__inactive-icon {
  filter: invert(.5);
  transition: all .2s;
  position: absolute;
}

.checkbox__active-icon {
  opacity: 0;
  transition: all .4s;
  position: absolute;
}

.checkbox__value {
  transition: all .2s;
}

.checkbox:hover {
  color: #fff;
}

.checkbox:hover .checkbox__inactive-icon {
  filter: invert(0);
}

.checkbox--checked .checkbox__value, .checkbox > input:checked ~ .checkbox__value, .checkbox > input:checked ~ * .checkbox__value {
  color: #fff;
}

.checkbox--checked .checkbox__active-icon, .checkbox > input:checked ~ .checkbox__active-icon, .checkbox > input:checked ~ * .checkbox__active-icon {
  opacity: 1;
}

.checkbox--disabled .checkbox__icon, .checkbox > input:disabled ~ .checkbox__icon, .checkbox > input:disabled ~ * .checkbox__icon {
  filter: invert(.5);
}

.checkbox--disabled .checkbox__value, .checkbox > input:disabled ~ .checkbox__value, .checkbox > input:disabled ~ * .checkbox__value {
  color: #777 !important;
}

.switch {
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  color: #777;
  text-transform: uppercase;
  align-items: stretch;
  font-weight: bold;
  display: flex;
  position: relative;
}

.switch > input {
  display: none;
}

.switch__graphic {
  height: 40px;
  width: 10px;
  border: 3px solid #777;
  border-radius: 9999px;
  margin-right: 8px;
  padding: 4px;
  transition: all .4s;
}

.switch:hover .switch__graphic {
  border-color: #fff;
}

.switch__slide {
  height: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.switch__icon {
  width: 12px;
  transition: all .2s;
  position: absolute;
  top: 0;
}

.switch__entries {
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 0;
  display: flex;
}

.switch__entries > * {
  transition: all .4s;
}

.switch__entries > :nth-child(1) {
  color: #fff;
}

.switch--disabled .switch__graphic, .switch > input:disabled ~ .switch__graphic, .switch > input:disabled ~ * .switch__graphic {
  border-color: #777 !important;
}

.switch--disabled .switch__icon, .switch > input:disabled ~ .switch__icon, .switch > input:disabled ~ * .switch__icon {
  filter: invert(.5);
}

.switch--disabled .switch__entries > *, .switch > input:disabled ~ .switch__entries > *, .switch > input:disabled ~ * .switch__entries > * {
  color: #777 !important;
}

.switch--checked .switch__icon, .switch > input:checked ~ .switch__icon, .switch > input:checked ~ * .switch__icon {
  top: 100%;
  transform: translateY(-100%);
}

.switch--checked .switch__entries > :nth-child(2), .switch > input:checked ~ .switch__entries > :nth-child(2), .switch > input:checked ~ * .switch__entries > :nth-child(2) {
  color: #fff;
}

.switch--checked .switch__entries > :nth-child(1), .switch > input:checked ~ .switch__entries > :nth-child(1), .switch > input:checked ~ * .switch__entries > :nth-child(1) {
  color: #777;
}

.fieldset {
  border: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  display: flex;
}

.fieldset__title {
  color: #b4d678;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.fieldset__body {
  margin: -4px -20px;
}

.fieldset__body > * {
  padding: 4px 20px;
}

.fieldset--vertical .fieldset__body, .fieldset .fieldset__body {
  flex-direction: column;
  display: flex;
}

.fieldset--horizontal .fieldset__body {
  flex-flow: wrap;
  display: flex;
}

.fieldset--full-width {
  width: 100%;
}

.fieldset > :not(:first-child) {
  margin-top: 4px;
}

.fieldset--disabled .checkbox__icon {
  filter: invert(.5);
}

.fieldset--disabled .checkbox__value {
  color: #777 !important;
}

.mobile-copyright {
  visibility: visible;
}

@media (min-width: 1024px) {
  .mobile-copyright {
    visibility: hidden;
  }
}

.desktop-copyright {
  visibility: hidden;
  width: 100%;
  max-height: 48px;
  justify-content: center;
  align-items: stretch;
  display: flex;
  position: fixed;
  bottom: 32px;
  left: 0;
}

.desktop-copyright > :not(:first-child) {
  margin-left: 8px;
}

.desktop-copyright__content {
  visibility: visible;
  opacity: 1;
  background: #000c;
  flex-shrink: 0;
  transition: all .2s;
  overflow: hidden;
}

.desktop-copyright__content__container {
  color: #fff;
  white-space: nowrap;
  padding: 12px;
}

.desktop-copyright--closed .desktop-copyright__content {
  visibility: hidden;
  max-width: 0 !important;
}

@media (min-width: 1024px) {
  .desktop-copyright {
    visibility: visible;
  }
}

.point-info-window {
  width: 100%;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  background: #000c;
  padding: 32px 16px 16px;
  transition: all .2s;
  position: fixed;
}

.point-info-window--active {
  visibility: visible;
  opacity: 1;
}

.point-info-window__header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.point-info-window__title {
  text-align: left;
  color: #fff;
  flex-grow: 1;
  font-weight: bold;
}

.point-info-window__description {
  text-align: left;
  color: #fff;
  margin-top: 16px;
}

.point-info-window__content {
  max-height: 100px;
  grid-template-columns: 2fr 3fr;
  gap: 8px;
  margin-top: 16px;
  display: grid;
  overflow-y: scroll;
}

.point-info-window__content * {
  text-align: left;
}

.point-info-window__content > :nth-child(2n+1) {
  color: #777;
}

.point-info-window__content > :nth-child(2n) {
  color: #fff;
}

.point-info-window__content--hidden {
  display: none;
}

@media (max-width: 1023px) {
  .point-info-window {
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
  }
}

@media (min-width: 1024px) {
  .point-info-window {
    max-width: 400px;
    bottom: 16px !important;
    left: 15px !important;
  }
}

.left-sidebar {
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all .2s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(calc(8px - 100%));
}

.left-sidebar__legend {
  opacity: .8;
  height: 100%;
  background-color: #000;
  flex-direction: column;
  align-items: center;
  padding-right: 8px;
  display: flex;
}

.left-sidebar__value {
  text-align: center;
  color: #fff;
  padding: 8px;
}

.left-sidebar__value:empty {
  display: none;
}

.left-sidebar__actions {
  display: flex;
  position: absolute;
  top: 4px;
  right: 0;
  transform: translateX(100%);
}

.left-sidebar__actions > :not(:last-child) {
  margin-right: 8px;
}

.left-sidebar__toggler {
  width: 32px;
  height: 32px;
  background: #000c;
  padding-left: 8px;
}

.left-sidebar--shrank, .left-sidebar--shrank .left-sidebar {
  transform: translateX(calc(8px - 100%));
}

.left-sidebar--shrank__legend, .left-sidebar--shrank .left-sidebar__legend {
  display: block;
}

.left-sidebar--shrank #legend-toggler, .left-sidebar--shrank .left-sidebar #legend-toggler {
  display: flex;
}

.left-sidebar--extended, .left-sidebar--extended .left-sidebar {
  transform: translateX(0);
}

.left-sidebar--extended__legend, .left-sidebar--extended .left-sidebar__legend {
  display: block;
}

.left-sidebar--extended #legend-toggler, .left-sidebar--extended .left-sidebar #legend-toggler {
  display: flex;
}

.left-sidebar--hidden__legend, .left-sidebar--hidden .left-sidebar__legend, .left-sidebar--hidden #legend-toggler, .left-sidebar--hidden .left-sidebar #legend-toggler {
  display: none;
}

@media (min-width: 1024px) {
  .left-sidebar {
    transform: translateX(0);
  }

  .left-sidebar__legend {
    display: block;
  }

  .left-sidebar #legend-toggler {
    display: flex;
  }

  .left-sidebar__legend {
    height: auto;
    padding: 0;
  }

  .left-sidebar__actions {
    display: none;
  }
}

.range-slider {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (max-width: 1023px) {
  .range-slider {
    flex-wrap: wrap;
  }
}

.range-slider input {
  width: 12.5em;
  height: 18px;
  background: none;
  margin: 0;
  padding: 0;
  font: 1em / 1 arial, sans-serif;
}

.range-slider input, .range-slider input::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.range-slider input::-webkit-slider-runnable-track {
  box-sizing: border-box;
  width: 12.5em;
  height: 3px;
  background: #666;
  border: none;
  padding: 0 4px;
}

.range-slider input::-moz-range-track {
  box-sizing: border-box;
  width: 12.5em;
  height: 3px;
  background: #666;
  border: none;
  padding: 0 4px;
}

.range-slider input::-ms-track {
  box-sizing: border-box;
  width: 12.5em;
  height: 3px;
  background: #666;
  border: none;
  padding: 0 4px;
}

.range-slider input::-webkit-slider-thumb {
  box-sizing: border-box;
  width: 16px;
  height: 18px;
  background-color: #0000;
  background-image: url("checkbox_active.96e5372d.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  margin-top: -7.5px;
}

.range-slider input::-moz-range-thumb {
  box-sizing: border-box;
  width: 16px;
  height: 18px;
  background-color: #0000;
  background-image: url("checkbox_active.96e5372d.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.range-slider input::-ms-thumb {
  box-sizing: border-box;
  width: 16px;
  height: 18px;
  background-color: #0000;
  background-image: url("checkbox_active.96e5372d.svg");
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  margin-top: 0;
}

.range-slider input::-ms-tooltip {
  display: none;
}

.range-slider .min, .range-slider .max {
  color: #fff;
}

@media (max-width: 1023px) {
  .range-slider .min, .range-slider .max {
    margin-top: 4px;
  }
}

@media (min-width: 1024px) {
  .range-slider .min {
    order: -1;
    margin-right: 8px;
  }

  .range-slider .max {
    order: 1;
    margin-left: 8px;
  }
}

h1 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.375;
}

body {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.375;
}

body--active {
  font-weight: 600;
}

* {
  overscroll-behavior: none;
  scrollbar-width: auto;
  scrollbar-color: #b4d678 #333;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #4f4f4f;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb {
  background-color: #b4d678;
  border: 1px solid #4f4f4f;
  border-radius: 999px;
}

.property {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  position: relative;
}

.property__header {
  cursor: pointer;
  border-bottom: 2px solid #b4d678;
  align-items: center;
  padding: 8px;
  display: flex;
}

.property__header > :not(:first-child) {
  margin-left: 10px;
}

.property__title {
  text-transform: uppercase;
  color: #fff;
  text-align: left;
  flex-grow: 1;
  font-weight: bold;
}

.property__status {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.property__status__icon {
  width: 14px;
  height: 14px;
  position: absolute;
}

.property__body {
  transition: all .2s;
  overflow: hidden;
}

.property__content {
  flex-wrap: wrap;
  align-items: flex-start;
  margin: -24px;
  padding: 24px 12px;
  display: flex;
}

.property__content > * {
  padding: 14px;
}

.property .expand-minimize__plus-icon {
  transform: scaleY(0);
}

.property--closed .property__status .expand-minimize__plus-icon {
  transform: scaleY(1);
}

.property--closed .property__body {
  max-height: 0 !important;
}

.property--active .property__icon {
  filter: brightness() invert(0);
}

.floating-window {
  background: #000c;
  padding: 16px 13px;
  transition: all .2s;
  position: fixed;
}

.floating-window__header {
  text-transform: uppercase;
  text-align: left;
  border-bottom: 1px solid #b4d678;
  padding: 0 3px;
  font-weight: bold;
}

.floating-window__content {
  flex-direction: column;
  align-items: stretch;
  margin-top: 4px;
  padding: 0 3px;
  display: flex;
}

.floating-window__item {
  min-width: 150px;
  justify-content: space-between;
  display: flex;
}

.floating-window__item__value {
  text-transform: uppercase;
  font-weight: bold;
}

.floating-window__arrow {
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  border-left: 8px solid #000c;
  position: absolute;
  right: -8px;
}

.sidebar {
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 40px);
  background: #000c;
  flex-direction: column;
  align-items: stretch;
  padding: 16px 40px 16px 24px;
  font-size: 14px;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
}

.sidebar__header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sidebar__logo-minimized {
  display: none;
}

.sidebar__logo-maximized {
  -webkit-user-select: none;
  user-select: none;
}

.sidebar__minimize-button .toggle-menu-icon, .sidebar__minimize-button .open-menu-icon {
  display: none;
}

.sidebar__content {
  box-sizing: border-box;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 24px;
  display: flex;
  overflow-y: auto;
}

.sidebar__content > :not(:first-child) {
  margin-top: 24px;
}

.sidebar .property-icon, .sidebar .property__icon {
  width: 24px;
  height: 24px;
}

.sidebar__footer {
  margin-top: 8px;
}

.sidebar--minimizing {
  transition: all .4s;
  transform: translateX(100%);
}

.sidebar--minimizing-2 {
  transform: translateX(100%);
}

.sidebar--minimized {
  transition: all .4s;
  transform: translateX(0%);
}

.sidebar--minimizing-2, .sidebar--minimized, .sidebar--maximizing {
  width: 72px;
  height: auto;
  padding: 8px 20px 24px;
}

.sidebar--minimizing-2 .property, .sidebar--minimized .property, .sidebar--maximizing .property {
  color: #fff;
  align-items: center;
}

.sidebar--minimizing-2 .property__title, .sidebar--minimizing-2 .property__status, .sidebar--minimized .property__title, .sidebar--minimized .property__status, .sidebar--maximizing .property__title, .sidebar--maximizing .property__status {
  display: none;
}

.sidebar--minimizing-2 .property__header, .sidebar--minimized .property__header, .sidebar--maximizing .property__header {
  border: none;
  padding: 0;
}

.sidebar--minimizing-2 .property__body, .sidebar--minimized .property__body, .sidebar--maximizing .property__body {
  display: none;
}

.sidebar--minimizing-2 .property__status .expand-minimize__plus-icon, .sidebar--minimized .property__status .expand-minimize__plus-icon, .sidebar--maximizing .property__status .expand-minimize__plus-icon {
  transform: scaleY(1);
}

.sidebar--minimizing-2 .property__body, .sidebar--minimized .property__body, .sidebar--maximizing .property__body {
  max-height: 0 !important;
}

.sidebar--minimizing-2 .property:hover__floating-window, .sidebar--minimized .property:hover__floating-window, .sidebar--maximizing .property:hover__floating-window {
  visibility: visible;
  opacity: 1;
}

.sidebar--minimizing-2 .sidebar__header, .sidebar--minimized .sidebar__header, .sidebar--maximizing .sidebar__header {
  flex-direction: column;
  justify-content: center;
}

.sidebar--minimizing-2 .sidebar__logo-maximized, .sidebar--minimized .sidebar__logo-maximized, .sidebar--maximizing .sidebar__logo-maximized, .sidebar--minimizing-2 .sidebar__minimize-button .toggle-menu-icon, .sidebar--minimized .sidebar__minimize-button .toggle-menu-icon, .sidebar--maximizing .sidebar__minimize-button .toggle-menu-icon, .sidebar--minimizing-2 .sidebar__minimize-button .close-menu-icon, .sidebar--minimized .sidebar__minimize-button .close-menu-icon, .sidebar--maximizing .sidebar__minimize-button .close-menu-icon {
  display: none;
}

.sidebar--minimizing-2 .sidebar__minimize-button .open-menu-icon, .sidebar--minimized .sidebar__minimize-button .open-menu-icon, .sidebar--maximizing .sidebar__minimize-button .open-menu-icon {
  display: flex;
}

.sidebar--minimizing-2 .sidebar__content, .sidebar--minimized .sidebar__content, .sidebar--maximizing .sidebar__content {
  margin-top: 8px;
}

.sidebar--minimizing-2 .sidebar__content > :not(:first-child), .sidebar--minimized .sidebar__content > :not(:first-child), .sidebar--maximizing .sidebar__content > :not(:first-child) {
  margin-top: 0;
}

.sidebar--minimizing-2 .sidebar__content > :not(.property--active), .sidebar--minimized .sidebar__content > :not(.property--active), .sidebar--maximizing .sidebar__content > :not(.property--active), .sidebar--minimizing-2 .sidebar__footer, .sidebar--minimized .sidebar__footer, .sidebar--maximizing .sidebar__footer {
  display: none;
}

.sidebar--maximizing {
  transition: all .4s;
  transform: translateX(100%);
}

.sidebar--maximizing-2 {
  transform: translateX(100%);
}

.sidebar--maximized {
  transition: all .4s;
  transform: translateX(0%);
}

@media (min-width: 1024px) {
  .sidebar {
    width: 490px;
    padding: 60px 50px 60px 60px;
    font-size: 16px;
  }

  .sidebar__header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .sidebar__logo-minimized {
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 10px;
    display: none;
  }

  .sidebar__logo-maximized {
    -webkit-user-select: none;
    user-select: none;
  }

  .sidebar__minimize-button .toggle-menu-icon {
    display: flex;
  }

  .sidebar__minimize-button .close-menu-icon {
    display: none;
  }

  .sidebar__content {
    width: calc(100% + 33px);
    box-sizing: border-box;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 80px;
    padding-right: 25px;
    display: flex;
    overflow-y: auto;
  }

  .sidebar__content > :not(:first-child) {
    margin-top: 24px;
  }

  .sidebar__footer {
    display: none;
  }

  .sidebar .property__window > * {
    padding: 24px;
  }

  .sidebar--minimizing-2, .sidebar--minimized, .sidebar--maximizing {
    width: 96px;
    height: 100%;
    padding: 35px;
  }

  .sidebar--minimizing-2 .sidebar__logo-minimized, .sidebar--minimized .sidebar__logo-minimized, .sidebar--maximizing .sidebar__logo-minimized {
    display: block;
  }

  .sidebar--minimizing-2 .sidebar__minimize-button, .sidebar--minimized .sidebar__minimize-button, .sidebar--maximizing .sidebar__minimize-button {
    transform: rotate(180deg);
  }

  .sidebar--minimizing-2 .sidebar__minimize-button .toggle-menu-icon, .sidebar--minimized .sidebar__minimize-button .toggle-menu-icon, .sidebar--maximizing .sidebar__minimize-button .toggle-menu-icon {
    display: flex;
  }

  .sidebar--minimizing-2 .sidebar__minimize-button .open-menu-icon, .sidebar--minimized .sidebar__minimize-button .open-menu-icon, .sidebar--maximizing .sidebar__minimize-button .open-menu-icon {
    display: none;
  }

  .sidebar--minimizing-2 .sidebar__content, .sidebar--minimized .sidebar__content, .sidebar--maximizing .sidebar__content {
    width: 100%;
    margin-top: 70px;
    padding: 0;
  }

  .sidebar--minimizing-2 .sidebar__content > :not(:first-child), .sidebar--minimized .sidebar__content > :not(:first-child), .sidebar--maximizing .sidebar__content > :not(:first-child) {
    margin-top: 60px;
  }

  .sidebar--minimizing-2 .sidebar__content > :not(.property--active), .sidebar--minimized .sidebar__content > :not(.property--active), .sidebar--maximizing .sidebar__content > :not(.property--active) {
    display: flex;
  }
}

.top-nav {
  visibility: hidden;
  opacity: 0;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
  transition: opacity .2s;
  display: flex;
  position: fixed;
  top: 4px;
  left: 16px;
}

.top-nav__toggle {
  width: 32px;
  height: 32px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
}

.top-nav__toggle__icon {
  filter: brightness(0) invert();
}

.top-nav__toggle--active .top-nav__toggle__icon {
  filter: brightness() invert(0);
}

.top-nav__toggle__window {
  display: none;
}

.top-nav--active {
  visibility: visible;
  opacity: 1;
}

.top-nav__background {
  width: 100%;
  height: 100%;
  background: #000c;
  position: absolute;
  top: 0;
  left: 0;
}

.top-nav__content {
  position: relative;
}

.top-nav__property__content {
  min-width: 220px;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  display: flex;
}

.top-nav__property:not(:first-child) {
  border-top: 2px solid #b4d678;
  margin-top: 8px;
}

.top-nav__property > * {
  position: relative;
}

.top-nav__property__name {
  text-transform: uppercase;
  font-weight: bold;
}

.top-nav__property__options {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.top-nav__property__options > :not(:first-child) {
  margin-left: 8px;
}

.top-nav__property__options .opacity {
  display: none;
}

.top-nav__modal {
  box-sizing: border-box;
  position: static;
}

.top-nav__modal input {
  width: 100%;
  margin: 0;
}

@media (min-width: 1024px) {
  .top-nav {
    visibility: visible;
    opacity: 1;
    padding: 16px 44px 8px;
    display: flex;
    position: fixed;
    transform: translateX(-50%);
    top: 0 !important;
    left: 50% !important;
  }

  .top-nav__toggle {
    display: none;
  }

  .top-nav__background {
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
  }

  .top-nav__property__options .opacity {
    display: block;
  }

  .top-nav__property:not(:first-child) {
    border-top: 1px solid #b4d678;
    margin-top: 0;
  }

  .top-nav__modal {
    width: 80%;
    visibility: hidden;
    opacity: 0;
    background: #000c;
    padding: 16px;
    transition: all .2s;
    position: fixed;
  }

  .top-nav__modal--active {
    visibility: visible;
    opacity: 1;
  }
}

/*# sourceMappingURL=components.e603dd38.css.map */
