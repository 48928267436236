@import "./base";

@mixin icon {
  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;
}
@mixin icon--pointer {
  cursor: pointer;
}

@mixin icon--medium {
  width: 32px;
  height: 32px;
}
@mixin icon--large {
  width: 48px;
  height: 48p;
}

@mixin icon--dark {
  background-color: rgba(0, 0, 0, 0.8);
}

@mixin icon--filter-none {
  filter: brightness(1) invert(0);
}
@mixin icon--filter-black {
  filter: brightness(0) invert(0);
}
@mixin icon--filter-white {
  filter: brightness(0) invert(1);
}

// SENTINEL, CLIMATE, TERRAIN, PREDICTIONS / PREDICTIONS / UNCERTAINTY ICONS
@mixin property-icon--active {
  filter: brightness(1) invert(0);
}
.property-icon {
  @include icon();
  @include icon--medium();
  @include icon--filter-white()
}

// COPYRIGHT INFORMATION ICON
.information-icon {
  @include icon();
  @include icon--large();
  @include icon--dark();
  @include icon--pointer();

  transition: $transition-duration-short;

  &:hover {
    background: $color-charcoal-dark;
  }
}

// EXPAND / MINIMIZE MENU
.toggle-menu-icon {
  @include icon();
  @include icon--medium();
  @include icon--pointer();

  transition: $transition-duration-short;

  &:hover {
    background: $color-charcoal-dark;
  }
}

// POINT VISIBILITY ICON
.point-visibility {
  @include icon();
  @include icon--medium();
  @include icon--pointer();

  position: relative;

  &__inactive-icon {
    position: absolute;

    filter: invert(0.5);
    transition: $transition-duration-short;
  }
  &:hover &__inactive-icon {
    filter: invert(0);
  }

  &__active-icon {
    position: absolute;
    opacity: 0;

    filter: brightness(2);
    transition: $transition-duration-short;
  }
  &:hover &__active-icon {
    filter: brightness(1);
  }

  &--active & {
    &__active-icon {
      opacity: 1;
    }

    &__inactive-icon {
      opacity: 0;
    }
  }
}

// POINT VISIBILITY ICON ( this one is called the same as the previous one according to design but this is a expand/minimize icon used in accordions (properties) )

@mixin expand-minimize--active {
  &__plus-icon {
    transform: scaleY(0);
  }
}

@mixin expand-minimize--inactive {
  &__plus-icon {
    transform: scaleY(1);
  }
}
.expand-minimize {
  @include icon();
  @include icon--medium();
  @include icon--pointer();

  transition: $transition-duration-short;

  &:hover {
    background: $color-charcoal-dark;
  }

  &__minus-icon, &__plus-icon {
    position: absolute;
    transition: $transition-duration-short;
  }

  &--active & {
    &__plus-icon {
      transform: scaleY(0);
    }
  }

  &--inactive & {
    &__plus-icon {
      transform: scaleY(1);
    }
  }

  // Default modifier
  // @extend .expand-minimize--inactive;
  @include expand-minimize--inactive;
}

// POINT INFO icons

// Minimise/expand
.point-info-toggle {
  @include icon();
  @include icon--medium();
  @include icon--pointer();

  position: relative;

  &__inactive-icon,
  &__active-icon {
    position: absolute;

    filter: invert(0.5);
    transition: $transition-duration-medium;
  }

  &__inactive-icon {
    opacity: 1;
  }
  &__active-icon {
    opacity: 0;
  }

  &:hover & {
    &__inactive-icon,
    &__active-icon {
      filter: invert(0);
    }
  }

  &--active & {
    &__inactive-icon {
      opacity: 0;
    }
    &__active-icon {
      opacity: 1;
    }
  }
}

// Close
.point-info-close {
  @include icon();
  @include icon--medium();
  @include icon--pointer();

  &__icon {
    transition: $transition-duration-medium;

    filter: invert(0.5);
  }

  &:hover & {
    &__icon {
      filter: invert(0);
    }
  }
}

.close-menu-icon, .open-menu-icon {
  @include icon();
  @include icon--medium();
  @include icon--pointer();

  transition: $transition-duration-short;

  &:hover {
    background: $color-charcoal-dark;
  }
}

// Option Text

@mixin option--active {
  color: $color-hrsoil-green;
}
.option {
  color: white;
  user-select: none;
  cursor: pointer;
  transition: $transition-duration-short;

  &:hover {
    color: $color-hrsoil-green;
  }

  &--active {
    @include option--active();
  }
}

// Legend Icon
@mixin legend-toggler--default {
  img {
    @include icon--filter-white();
  }
}
@mixin legend-toggler--active {
  img {
    @include icon--filter-none();
  }
}
.legend-toggler {
  @include icon();
  @include icon--dark();
  @include icon--pointer();

  &:hover {
    img {
      @include icon--filter-none();
    }
  }

  @include legend-toggler--default();
  &--active {
    @include legend-toggler--active();
  }
}

// Opacity Icon
@mixin opacity-toggler--default {
  img {
    @include icon--filter-white();
  }
}
@mixin opacity-toggler--active {
  img {
    @include icon--filter-none();
  }
}
.opacity-toggler {
  @include icon();
  @include icon--dark();
  @include icon--pointer();

  &:hover {
    img {
      @include icon--filter-none();
    }
  }

  @include opacity-toggler--default();
  &--active {
    @include opacity-toggler--active();
  }
}