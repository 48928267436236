@import "./base";

@mixin property--minified {
  &__title,
  &__status {
    display: none;
  }
  &__header {
    border: none;
    padding: 0;
  }
  &__body {
    display: none;
  }
}

@mixin property--closed {
  &__status {
    .expand-minimize {
      @include expand-minimize--inactive;
    }
  }

  &__body {
    max-height: 0px !important;
  }
}

// @mixin property--open {
//   &__status {
//     .expand-minimize {
//       @extend .expand-minimize--inactive;
//     }
//   }

//   &__body {
//     max-height: 0px !important;
//   }
// }

@mixin property--desktop {
  &__window {
    & > * {
      padding: 24px;
    }
  }
}

@mixin property--floating-window-active-hover {
  &:hover & {
    &__floating-window {
      visibility: visible;
      opacity: 1;
    }
  }
}

.property {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  user-select: none;
  position: relative;

  &__header {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 2px solid $color-hrsoil-green;
    cursor: pointer;

    & > *:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__icon {
    @extend .property-icon;
  }

  &__title {
    flex-grow: 1;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    text-align: left;
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__icon {
      position: absolute;
      width: 14px;
      height: 14px;
    }
  }

  &__body {
    overflow: hidden;
    transition: $transition-duration-short;
  }
  &__content {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 24px 12px;

    margin: -24px;
    & > * {
      padding: 14px;
    }
  }

  .expand-minimize {
    @include expand-minimize--active;
  }

  &--closed & {
    &__status {
      .expand-minimize {
        @include expand-minimize--inactive;
      }
    }

    &__body {
      max-height: 0px !important;
    }
  }

  &--active & {
    &__icon {
      @include property-icon--active;
    }
  }

  // &__floating-window {
  //   position: fixed;
  //   left: -8px;
  //   transform: translateX(-100%) translateY(-16px);

  //   visibility: hidden;
  //   opacity: 0;
  //   transition: .2s;
  // }

  // @include property--floating-window-active-hover();
}

// .floating-window {
//   padding-right: 8px;
//   position: relative;

//   &::before {
//     content: '';

//       position: absolute;
//       right: 0;
//       top: 24px;

//       width: 0; 
//       height: 0; 
//       border-top: 8px solid transparent;
//       border-bottom: 8px solid transparent;
      
//       border-left: 8px solid rgba(0, 0, 0, 0.8);
//   }

//   &__container {
//     padding: 16px;
//     background: rgba(0, 0, 0, 0.8);

//     &__header {
//       border-bottom: 1px solid $color-hrsoil-green;
//     }
//   }
// }

.floating-window {
  padding: 16px 13px;
  background: rgba(0, 0, 0, 0.8);
  transition: .2s;
  position: fixed;

  &__header {
    padding: 0 3px;
    border-bottom: 1px solid $color-hrsoil-green;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }
  &__content {
    padding: 0 3px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    
    min-width: 150px;

    &__name {

    }

    &__value {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__arrow {
    position: absolute;
    right: -8px;

    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    
    border-left: 8px solid rgba(0, 0, 0, 0.8);
  }
}

// Mobile
.sidebar {
  position: fixed;

  height: 100%;
  right: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: calc(100% - 40px);
  padding: 16px 40px 16px 24px;

  font-size: 14px;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__logo-minimized {
    display: none;
  }

  &__logo-maximized {
    user-select: none;
  }

  &__minimize-button {
    .toggle-menu-icon {
      display: none;
    }
    .open-menu-icon {
      display: none;
    }
  }

  &__content {
    margin-top: 24px;
    overflow-y: auto;
    flex-grow: 1;

    /* 8px is the scrollbar, 25px is the distance between scrollbar and container */
    // width: calc(100% + 25px + 8px);
    box-sizing: border-box;
    // padding-right: 25px;

    display: flex;
    flex-direction: column;

    & > *:not(:first-child) {
      margin-top: 24px;
    }
  }

  .property-icon {
    width: 24px;
    height: 24px;
  }

  &__footer {
    margin-top: 8px;
  }

  // Transition
  &--minimizing {
    transition: $transition-duration-medium;
    transform: translateX(100%);
  }
  &--minimizing-2 {
    transform: translateX(100%);
  }
  &--minimized {
    transition: $transition-duration-medium;
    transform: translateX(0%);
  }

  // Styles for mini version of sidebar
  &--minimizing-2, &--minimized, &--maximizing {
    width: 72px;
    padding: 8px 20px 24px 20px;
    height: auto;

    .property {
      color: white;
      @include property--minified;
      @include property--closed;
      // @include property--floating-window-active-hover();
      align-items: center;
    }
    .property {
      // .property__floating-window {
      //   visibility: visible;
      //   opacity: 1;
      // }
    }
    .property:hover {
      &__floating-window {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  // Styles for mini version of sidebar
  &--minimizing-2 &, &--minimized &, &--maximizing & {
    &__header {
      flex-direction: column;
      justify-content: center;
    }
    &__logo-maximized {
      display: none;
    }
    &__minimize-button {
      .toggle-menu-icon {
        display: none;
      }
      .close-menu-icon {
        display: none;
      }
      .open-menu-icon {
        display: flex;
      }
    }

    &__content {
      margin-top: 8px;
      & > *:not(:first-child) {
        margin-top: 0px;
      }

      & > *:not(.property--active) {
        display: none;
      }
    }

    &__footer {
      display: none;
    }
  }

  &--maximizing {
    transition: $transition-duration-medium;
    transform: translateX(100%);
  }
  &--maximizing-2 {
    transform: translateX(100%);
  }
  &--maximized {
    transition: $transition-duration-medium;
    transform: translateX(0%);
  }

  @include media(">=desktop") {
    width: 490px;
    padding: 60px 50px 60px 60px;

    font-size: 16px;
  
    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__logo-minimized {
      margin-bottom: 10px;
      user-select: none;
      display: none;
    }

    &__logo-maximized {
      user-select: none;
    }
  
    &__minimize-button {
      .toggle-menu-icon {
        display: flex;
      }
      .close-menu-icon {
        display: none;
      }
    }
  
    &__content {
      margin-top: 80px;
      overflow-y: auto;
      flex-grow: 1;
  
      /* 8px is the scrollbar, 25px is the distance between scrollbar and container */
      width: calc(100% + 25px + 8px);
      box-sizing: border-box;
      padding-right: 25px;
  
      display: flex;
      flex-direction: column;
  
      & > *:not(:first-child) {
        margin-top: 24px;
      }
    }

    &__footer {
      display: none;
    }

    .property {
      @include property--desktop;
    }

    // Styles for mini version of sidebar
    &--minimizing-2, &--minimized, &--maximizing {
      width: 96px;
      padding: 35px;
      height: 100%;
    }

    // Styles for mini version of sidebar
    &--minimizing-2 &, &--minimized &, &--maximizing & {
      &__logo-minimized {
        display: block;
      }
      &__minimize-button {
        transform: rotate(180deg);

        .toggle-menu-icon {
          display: flex;
        }
        .open-menu-icon {
          display: none;
        }
      }
      &__content {
        margin-top: 70px;
        padding: 0;
        width: 100%;

        & > *:not(:first-child) {
          margin-top: 60px;
        }
        & > *:not(.property--active) {
          display: flex;
        }
      }
    }
  }
}

.top-nav {
  // Mobile and desktop
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  
  // Mobile
  top: 4px;
  left: 16px;

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    width: 32px;
    height: 32px;
    
    user-select: none;
    cursor: pointer;
    
    &__icon {
      filter: brightness(0) invert(1);
    }

    &--active & {
      &__icon {
        filter: brightness(1) invert(0);
      }
    }

    ~ &__window {
      display: none;
    }
  }

  &--active {
    visibility: visible;
    opacity: 1;
  }

  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;
  position: fixed;

  transition: opacity $transition-duration-short;
  visibility: hidden;
  opacity: 0;

  &__background {
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__content {
    position: relative; // We need this because we want content to be in the foreground and background has potision absolute.
  }

  &__property {
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 220px;
      color: white;
      padding: 4px;
    }

    &:not(:first-child) {
      border-top: 2px solid $color-hrsoil-green;
      margin-top: 8px;
    }
    & > * {
      position: relative;
    }

    &__name {
      font-weight: bold;
      text-transform: uppercase;
    }

    &__options {
      display: flex;
      flex-direction: row;
      align-items: center;

      & > *:not(:first-child) {
        margin-left: 8px;
      }

      .opacity {
        display: none;
      }
    }
  }

  &__modal {
    box-sizing: border-box;
    position: static;

    input {
      width: 100%;
      margin: 0;
    }
  }

  @include media(">=desktop") {
    & {
      position: fixed;
      top: 0!important;
      left: 50%!important;
      transform: translateX(-50%);
  
      &__toggle {
        display: none;
      }
  
      display: flex;
      padding: 16px 44px 8px;
  
      visibility: visible;
      opacity: 1;
  
      &__background {
        clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
      }
  
      &__property {
        &__options {
          .opacity {
            display: block;
          }
        }

        &:not(:first-child) {
          border-top: 1px solid $color-hrsoil-green;
          margin-top: 0;
        }
      }
  
      &__modal {
        background: rgba(0, 0, 0, 0.8);
        padding: 16px;
        width: 80%;
        position: fixed;
  
        transition: $transition-duration-short;
        visibility: hidden;
        opacity: 0;
  
        &--active {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}