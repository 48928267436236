@import "./variables";

// @import "include-media/dist/_include-media.scss";
// @import "https://raw.githubusercontent.com/eduardoboucas/include-media/master/dist/_include-media.scss";
@import "./include-media";

// Font
h1 {
  font-size: 20px;
  line-height: calc(26/20);
  font-weight: bold;
}

h2 {
  font-size: 16px;
  line-height: calc(22/16);
  font-weight: bold;
}

body {
  font-size: 16px;
  line-height: calc(22/16);
  font-weight: normal;


  &--active {
    font-weight: 600;
  }
}

// Disable scroll to refresh on Android devices
* {
  overscroll-behavior: none;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: $color-hrsoil-green $color-charcoal-dark;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: $color-dark-gray;
  border-radius: 999px;
}

*::-webkit-scrollbar-thumb {
  background-color: $color-hrsoil-green;
  border-radius: 999px;
  border: 1px solid $color-dark-gray;
}