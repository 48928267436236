
// Primary Colors
$color-hrsoil-green: #B4D678;
$color-white: #FFFFFF;

// Secondary Colors
$color-none: #EEEEEE;
$color-dark-gray: #4F4F4F;

// Grayscales
$color-true-black: #000000;
$color-true-black-80: #000000CC;
$color-charcoal-dark: #333333;
$color-charcoal-black: #777777;


// State Colors
$color-system-red: #F15866;

// Animation
$transition-duration-short: .2s;
$transition-duration-medium: .4s;
$transition-duration-long: .6s;

// Responsive
$breakpoints: (phone: 320px, desktop: 1024px);