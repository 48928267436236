@import "./base";

// CHECKBOX
@mixin checkbox--checked {
  @at-root {
    &__value {
      color: white;
    }
    &__active-icon {
      opacity: 1;
    }
  }
}
@mixin checkbox--disabled {
  @at-root {
    &__icon {
      filter: invert(.5);
    }
    &__value {
      color: $color-charcoal-black!important;
    }
  }
}
.checkbox {
  // Block
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
  font-weight: bold;
  color: $color-charcoal-black;

  // Elements
  & > input {
    display: none;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  &__inactive-icon {
    position: absolute;

    transition: $transition-duration-short;

    filter: invert(.5);
  }

  &__active-icon {
    position: absolute;

    transition: $transition-duration-medium;
    opacity: 0;
  }

  &__value {
    transition: $transition-duration-short;
  }

  &:hover {
    color: white;
  }

  &:hover & {
    &__inactive-icon {
      filter: invert(0);
    }
  }

  // Modifiers
  &--checked &,
  & > input:checked ~ &,
  & > input:checked ~ * & {
    @include checkbox--checked();
  }

  &--disabled &,
  & > input:disabled ~ &,
  & > input:disabled ~ * & {
    @include checkbox--disabled();
  }
}

/* TOGGLE SWITCH */
@mixin switch--disabled {
  @at-root {
    &__graphic {
      border-color: $color-charcoal-black!important;
    }

    &__icon {
      filter: invert(.5);
    }

    &__entries > * {
      color: $color-charcoal-black!important;
    }
  }
}
@mixin switch--checked {
  @at-root {
    &__icon {
      top: 100%;
      transform: translateY(-100%);
    }
  
    &__entries {
      & > *:nth-child(2) {
        color: white;
      }
  
      & > *:nth-child(1) {
        color: $color-charcoal-black;
      }
    }
  }
}

.switch {
  // Block
  display: flex;
  align-items: stretch;
  
  position: relative;
  width: fit-content;

  cursor: pointer;
  user-select: none;
  color: $color-charcoal-black;

  text-transform: uppercase;
  font-weight: bold;

  & > input {
    display: none;
  }

  // Elements
  &__graphic {
    margin-right: 8px;
    padding: 4px;
    border-radius: 9999px;
    height: 40px;
    width: 10px;

    transition: $transition-duration-medium;
    border: 3px solid $color-charcoal-black;
  }

  &:hover &__graphic {
    border-color: white;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
  }

  &__icon {
    position: absolute;
    top: 0;

    transition: $transition-duration-short;
    width: 12px;
  }

  &__entries {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 0;
  }

  &__entries > * {
    transition: $transition-duration-medium;

    &:nth-child(1) {
      color: white;
    }
  }

  // Modifiers
  &--disabled &,
  & > input:disabled ~ &,
  & > input:disabled ~ * & {
    @include switch--disabled();
  }

  &--checked &,
  & > input:checked ~ &,
  & > input:checked ~ * & {
    @include switch--checked();
  }
}

@mixin fieldset--disabled {
  .checkbox {
    @include checkbox--disabled();
  }
}
.fieldset {
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__title {
    color: $color-hrsoil-green;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  &__body {
    margin: -4px -20px;
  }

  &__body > * {
    padding: 4px 20px;
  }

  &--vertical & {
    &__body {
      display: flex;
      flex-direction: column;
    }
  }

  &--horizontal & {
    &__body {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &--full-width {
    width: 100%;
  }

  // Default modifier
  @extend .fieldset--vertical;

  & > *:not(:first-child) {
    margin-top: 4px;
  }

  &--disabled {
    @include fieldset--disabled();
  }

}


// Mobile Copyright
.mobile-copyright {
  visibility: visible;

  @include media(">=desktop") {
    & {
      visibility: hidden;
    }
  }
}

// Desktop Copyright
@mixin desktop-copyright--closed {
  &__content {
    visibility: hidden;
    max-width: 0!important;
  }
}
.desktop-copyright {
  visibility: hidden;

  position: fixed;
  bottom: 32px;
  // left: 50%;
  left: 0;
  width: 100%;
  // transform: translateX(-50%);
  max-height: 48px;

  display: flex;
  align-items: stretch;
  justify-content: center;

  & > *:not(:first-child) {
    margin-left: 8px;
  }

  &__content {
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    
    transition: $transition-duration-short;
    visibility: visible;
    opacity: 1;

    &__container {
      color: white;
      padding: 12px;
      white-space: nowrap;
    }
  }

  &--closed & {
    @include desktop-copyright--closed();
  }

  @include media(">=desktop") {
    & {
      visibility: visible;
    }
  }
}

.point-info-window {
  position: fixed;
  
  width: 100%;

  padding: 32px 16px 16px;
  box-sizing: border-box;

  background: rgba(0, 0, 0, 0.8);

  visibility: hidden;
  opacity: 0;
  transition: .2s;

  &--active {
    visibility: visible;
    opacity: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    flex-grow: 1;
    text-align: left;
    color: white;
    font-weight: bold;
  }

  &__description {
    text-align: left;
    margin-top: 16px;
    color: white;
  }

  &__content {
    overflow-y: scroll;
    max-height: 100px;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 8px;
    margin-top: 16px;

    * {
      text-align: left;
    }
    & > *:nth-child(odd) {
      color: $color-charcoal-black;

    }
    & > *:nth-child(even) {
      color: white;
    }

    &--hidden {
      display: none;
    }
  }

  @include media("<desktop") {
    & {
      bottom: 0!important;
      left: 0!important;
      right: 0!important;
    }
  }

  @include media(">=desktop") {
    & {
      max-width: 400px;
      bottom: 16px!important;
      left: 15px!important;
    }
  }
}

// Left Sidebar
$left-sidebar--peek: 8px;
$left-sidebar-toggler-width: 32px + 8px; //icon = 32px, left-padding = 8px;

@mixin left-sidebar--hidden {
  @at-root {
    &__legend {
      display: none;
    }
    & #legend-toggler {
      display: none;
    }
  }
}
@mixin left-sidebar--visible {
  @at-root {
    &__legend {
      display: block;
    }
    & #legend-toggler {
      display: flex;
    }
  }
}
@mixin left-sidebar--shrank() {
  @include left-sidebar--visible();
  transform: translateX(calc(-100% + $left-sidebar--peek));
}
@mixin left-sidebar--extended() {
  @include left-sidebar--visible();
  transform: translateX(0);
}
.left-sidebar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;

  transform: translateX(calc(-100% + $left-sidebar--peek));
  transition: $transition-duration-short;

  &__legend {
    background-color: black;
    padding-right: $left-sidebar--peek;
    opacity: .8;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__value {
    text-align: center;
    color: white;
    padding: 8px;
  }

  &__value:empty {
    display: none;
  }

  &__actions {
    position: absolute;
    top: 4px;
    right: 0;
    transform: translateX(100%);

    display: flex;

    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__toggler {
    padding-left: 8px;
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, .8);
  }

  &--shrank,
  &--shrank & {
    @include left-sidebar--shrank();
  }
  &--extended,
  &--extended & {
    @include left-sidebar--extended();
  }

  &--hidden,
  &--hidden & {
    @include left-sidebar--hidden();
  }

  @include media(">=desktop") {
    & {
      @include left-sidebar--extended();

      &__legend {
        padding: 0;
        height: auto;
      }

      &__actions {
        display: none;
      }  
    }
    
  }
}

// Range Slider
$track-w: 12.5em;
$track-h: 3px;

$thumb-w: 16px;
$thumb-h: 18px;

@mixin track() {
	box-sizing: border-box;
	border: none;

	width: $track-w;
  height: $track-h;
	padding: 0 4px;
  
  background: #666666;
}

@mixin thumb() {
	box-sizing: border-box;
	border: none;
	
  width: $thumb-w;
	height: $thumb-h;

  background-image: url("~/src/assets/icons/checkbox_active.svg");
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
}

.range-slider {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media("<desktop") {
    flex-wrap: wrap;
  }

	input {
    // Style
    &, &::-webkit-slider-thumb {
      -webkit-appearance: none;
    }
    
    margin: 0;
    padding: 0;
    width: $track-w;
    height: $thumb-h;
    background: transparent;
    font: 1em/1 arial, sans-serif;
    
    &::-webkit-slider-runnable-track {
      @include track
    }
    &::-moz-range-track { @include track }
    &::-ms-track { @include track }
    
    &::-webkit-slider-thumb {
      margin-top: .5*($track-h - $thumb-h);
      @include thumb
    }
    &::-moz-range-thumb { @include thumb }
    &::-ms-thumb {
      margin-top: 0;
      @include thumb
    }
    
    &::-ms-tooltip { display: none }
  }

  .min, .max {
    color: white;
  }
  @include media("<desktop") {
    .min, .max {
      margin-top: 4px;
    }
  }
  @include media(">=desktop") {
    .min {
      order: -1;
      margin-right: 8px;
    }
    .max {
      order: 1;
      margin-left: 8px;
    }
  }
}